import { Publication } from "../client/interfaces";

export const mockPublications: Record<string, Publication | undefined> = {
  "636813": {
    contributors: [
      {
        firstName: "Foley, Lucy",
        surname: "",
        type: "aut",
      },
      {
        firstName: "Carlsen, Monica",
        surname: "",
        type: "oth",
      },
    ],
    coverUrl:
      "https://platform-staging.libry.dk/api/cover/customer/toensbergfaerderbib?data=W3sidHlwZSI6ImJpYmxpb2ZpbGlkIiwiaWRlbnRpZmllciI6IjE3OTE2NDA2In1d",
    id: "636813",
    publicationYear: "2023",
    title: "En leilighet i Paris",
  },
  "341561": {
    contributors: [
      {
        firstName: "Knudsen, Grete",
        surname: "",
        type: "aut",
      },
      {
        firstName: "Garvik, Olav",
        surname: "",
        type: "aut",
      },
    ],
    coverUrl:
      "https://platform-staging.libry.dk/api/cover/customer/toensbergfaerderbib?data=W3sidHlwZSI6ImJpYmxpb2ZpbGlkIiwiaWRlbnRpZmllciI6Ijk5MTQxNDUifV0%3D",
    id: "341561",
    publicationYear: "2013",
    title: "Basketak : mine år med Brundtland, Jagland og Stoltenberg",
  },
  "219265": {
    contributors: [
      {
        firstName: "Bellman, Carl Michael",
        surname: "",
        type: "aut",
      },
    ],
    coverUrl:
      "https://platform-staging.libry.dk/api/cover/customer/toensbergfaerderbib?data=W3sidHlwZSI6ImJpYmxpb2ZpbGlkIiwiaWRlbnRpZmllciI6IjQ4NTUzMTAifV0%3D",
    id: "219265",
    publicationYear: "1994",
    title: "Fredmans epistlar I-II : no. 1-35, CD 1 & 2",
  },
  "544751": {
    contributors: [
      {
        firstName: "Egerkrans, Johan",
        surname: "",
        type: "aut",
      },
      {
        firstName: "Ekelund, Øyvind Lysebo",
        surname: "",
        type: "trl",
      },
    ],
    coverUrl:
      "https://platform-staging.libry.dk/api/cover/customer/haugesundbib?data=W3sidHlwZSI6ImJpYmxpb2ZpbGlkIiwiaWRlbnRpZmllciI6IjEyMDQ1NjIzIn1d",
    id: "544751",
    publicationYear: "2019",
    title: "De levende døde",
  },
  "724561": {
    contributors: [
      {
        firstName: "Hjorth, Vigdis",
        surname: "",
        type: "aut",
      },
      {
        firstName: "Hjorth, Vigdis",
        surname: "",
        type: "oth",
      },
    ],
    coverUrl:
      "https://platform-staging.libry.dk/api/cover/customer/haugesundbib?data=W3sidHlwZSI6ImJpYmxpb2ZpbGlkIiwiaWRlbnRpZmllciI6IjIwOTcxMjk5In1d",
    id: "724561",
    publicationYear: "2023",
    title: "Gjentakelsen : roman",
  },
  "469219": {
    contributors: [
      {
        firstName: "Riley, Lucinda",
        surname: "",
        type: "aut",
      },
      {
        firstName: "Riley, Lucinda",
        surname: "",
        type: "oth",
      },
      {
        firstName: "Whittaker, Harry",
        surname: "",
        type: "oth",
      },
      {
        firstName: "Windt-Val, Benedicta",
        surname: "",
        type: "oth",
      },
    ],
    coverUrl:
      "https://platform-staging.libry.dk/api/cover/customer/tysvaerbib?data=W3sidHlwZSI6ImJpYmxpb2ZpbGlkIiwiaWRlbnRpZmllciI6IjIwODIxODgzIn1d",
    id: "469219",
    publicationYear: "2023",
    title: "Atlas : historien om Pa Salt",
  },
  "476731": {
    contributors: [
      {
        firstName: "Mytting, Lars",
        surname: "",
        type: "aut",
      },
      {
        firstName: "Mytting, Lars",
        surname: "",
        type: "oth",
      },
    ],
    coverUrl:
      "https://platform-staging.libry.dk/api/cover/customer/tysvaerbib?data=W3sidHlwZSI6ImJpYmxpb2ZpbGlkIiwiaWRlbnRpZmllciI6IjE2Mzk0NzgyIn1d",
    id: "476731",
    publicationYear: "2023",
    title: "Skråpånatta : roman",
  },
  "476958": {
    contributors: [
      {
        firstName: "Fossum, Karin",
        surname: "",
        type: "aut",
      },
      {
        firstName: "Fossum, Karin",
        surname: "",
        type: "oth",
      },
    ],
    coverUrl:
      "https://platform-staging.libry.dk/api/cover/customer/tysvaerbib?data=W3sidHlwZSI6ImJpYmxpb2ZpbGlkIiwiaWRlbnRpZmllciI6IjIwOTY5ODgwIn1d",
    id: "476958",
    publicationYear: "2023",
    title: "Farvel, Farah Diba",
  },
  "477106": {
    contributors: [
      {
        firstName: "Fjell, Jan-Erik",
        surname: "",
        type: "aut",
      },
      {
        firstName: "Fjell, Jan-Erik",
        surname: "",
        type: "oth",
      },
    ],
    coverUrl:
      "https://platform-staging.libry.dk/api/cover/customer/tysvaerbib?data=W3sidHlwZSI6ImJpYmxpb2ZpbGlkIiwiaWRlbnRpZmllciI6IjIxMDM0NzM2In1d",
    id: "477106",
    publicationYear: "2023",
    title: "Nattravnen",
  },
};
