export interface KeyValueStore<Data> {
  get: () => Data | undefined;
  set: (token: Data) => void;
  clear: () => void;
}

export const createSessionStore = <Data>({
  storage,
  key,
  onChange,
}: {
  storage: Storage;
  key: string;
  onChange?: (data: Data | undefined) => any;
}): KeyValueStore<Data> => {
  const store = {
    get: () => {
      const data = storage.getItem(key);
      return data ? (JSON.parse(data) as Data) : undefined;
    },
    set: (data: Data) => {
      storage.setItem(key, JSON.stringify(data));
      if (onChange) onChange(data);
    },
    clear: () => {
      storage.removeItem(key);
      if (onChange) onChange(undefined);
    },
  };
  // Set initial state upon loading
  if (onChange) onChange(store.get());
  return store;
};
