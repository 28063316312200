import { useState, MouseEventHandler, useCallback } from "react";
import { Box, ChakraProps, HStack, Link, Text, colors } from "@biblioteksentralen/js-utils";
import { useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { useTranslation } from "../../../utils/hooks/useTranslation";

export const HeaderUserInfo: React.FunctionComponent<ChakraProps> = (props) => {
  const { t } = useTranslation();
  const { user, rediaPlatform } = useRediaPlatformContext();
  const [justLoggedOut, setJustLoggedOut] = useState(false);

  const onLogoutClick: MouseEventHandler = useCallback(
    async (event) => {
      event.preventDefault();
      if (!rediaPlatform) return;
      await rediaPlatform.logout();
      setJustLoggedOut(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setJustLoggedOut(false);
    },
    [rediaPlatform]
  );

  if (justLoggedOut)
    return (
      <Box fontSize="xs" {...props}>
        {t("Takk for besøket 👋")}
      </Box>
    );
  if (!user) return null;

  return (
    <Text fontSize="xs" {...props}>
      <HStack alignItems="center">
        <Box fontWeight="600">
          {user.firstName} {user.surname}
        </Box>
        <Box marginTop="0.1rem" height="0.8rem" borderLeft="solid 0.1rem" borderColor={colors.grey15}></Box>
        <Link variant="plain" as="button" onClick={onLogoutClick}>
          {t("Logg ut")}
        </Link>
      </HStack>
    </Text>
  );
};
