import { useBoolean } from "@biblioteksentralen/js-utils";
import { useEffect } from "react";

/**
 * Indicate that a click has taken place, although its effect is not yet apparent
 */
export const useImmediateClickResponse = (clickHasTakenEffect = false): [boolean, () => void, () => void] => {
  const [hasClicked, { on: onClick, off: onClickHasTakenEffect }] = useBoolean();

  useEffect(() => {
    if (hasClicked && clickHasTakenEffect) onClickHasTakenEffect();
  }, [hasClicked, clickHasTakenEffect, onClickHasTakenEffect]);

  return [hasClicked, onClick, onClickHasTakenEffect];
};
